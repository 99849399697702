import React from 'react';
import * as styles from '../../styles/pages/shortHeightContent.module.css';
import GlobalWrapper from '../../components/GlobalWrapper/GlobalWrapper';
import ThankYou from '../../components/Form/ThankYou/ThankYou';
import Layout from "../../components/Layout";
import Title from '../../components/Title/Title';
import Footnotes from '../../components/Footnotes/Footnotes';

import { GtagHead } from '../../components/GTAG';

export function Head() {
    return (
      <>
        <GtagHead id='gtag' />
        <title>Request a Rep | LOQTORZI® (toripalimab-tpzi) thank you</title>
        <meta name='description' content='Thank you for requesting a representative for for questions on LOQTORZI®'/>
      </>
    )
}
export default function RequestARepConfirmation() {
  return (
    <Layout className={ styles.shortHeightContent } showIsi={ false }>
      <Title pageName={'Get updates'} center={true}>
        <div className='pt-[2.3rem]'>
          Thank you<br/>
          for submitting<br/>
          your request
        </div>
      </Title>
      <div className={ styles.content }>
        <GlobalWrapper className={'flex-1 tablet:w-full'} showBorderLeft={ true }>
          <ThankYou buttonLink="/" buttonText="Return Home">
            <p className='text-center mt-[1.5rem] font-MontserratMedium'>
                Coherus BioSciences<br/>
                will get back to you shortly.
            </p>
          </ThankYou>

          <Footnotes className='pt-[2.6rem] pb-[2rem] px-[1rem]' center={true} items={[
            {
              bullet: '',
              text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
            }
          ]} />
        </GlobalWrapper>
      </div>
    </Layout>
  );
}